<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <a-tooltip placement="top">
            <template slot="title"><span>Add Security Company</span></template>
            <a href="javascript: void(0);" class="btn btn-sm btn-success float-right mb-1" v-b-modal.add-update-security-company-modal @click="$bvModal.show('add-update-security-company-modal'), resetForm()">
              <i class="fa fa-plus"/> Add Security Company
            </a>
          </a-tooltip>
          <table class="table table-sm table-bordered text-center" id="security_companies_list">
            <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(security_company, index) in security_companies" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ security_company.name }}</td>
              <td>{{ security_company.status === 1 ? 'Active' : 'Inactive' }}</td>
              <td>
                <a class="btn btn-sm btn-primary mr-2" href="javascript: void(0);" @click.prevent="edit(security_company.id), $bvModal.show('add-update-security-company-modal')"><i class="fe fe-edit mr-1"></i>Update</a>
                <a class="btn btn-sm btn-danger mr-2" href="javascript: void(0);" @click.prevent="onDelete(security_company.id)"><i class="fa fa-trash mr-1"></i>Delete</a>
              </td>
            </tr>
            </tbody>
          </table>
          <a-skeleton active :loading="loading"></a-skeleton>
          <h5 class="text-center text-secondary" v-if="security_companies.length === 0 && !flag">
            <Empty :image="simpleImage"></Empty>
          </h5>
        </div>
      </div>
      <add-update-security-company
        :security_company="security_company"
        :btnEdit="btnEdit"
        @getSecurityCompanies="getSecurityCompanies"
        @resetForm="resetForm"
        ref="addUpdateSecurityCompany">
      </add-update-security-company>
    </div>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import apiClient from '@/services/axios'
import addUpdateSecurityCompany from '@/views/securityCompany/modal/addUpdateSecurityCompany'
import $ from 'jquery'

export default {
  name: 'List',
  components: {
    Empty,
    addUpdateSecurityCompany,
  },
  data() {
    return {
      security_companies: [],
      security_company: {},
      loading: false,
      loader: false,
      flag: false,
      btnEdit: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    }
  },
  mounted() {
    this.getSecurityCompanies()
  },
  methods: {
    getSecurityCompanies() {
      this.flag = true
      this.loading = true
      this.loader = true
      apiClient.get('api/security-companies')
        .then(response => {
          this.loading = false
          this.flag = true
          this.loader = false
          this.security_companies = response.data.security_companies
          setTimeout(function () {
            $('#security_companies_list').DataTable(
              {
                bRetrieve: true,
                pagingType: 'full_numbers',
                pageLength: 10,
                processing: true,
                dom: 'Blfrtip',
                buttons: ['copy', 'csv', 'print'],
              },
            )
          }, 3000,
          )
        })
        .catch(error => {
          console.log(error)
        })
    },
    edit(id) {
      this.security_company = {}
      this.btnEdit = true
      apiClient.get('api/security-companies/' + id + '/edit').then(response => {
        if (!response.data.error) {
          this.security_company = response.data.security_company
        }
      })
    },
    onDelete(Id) {
      if (confirm('Do you really want to delete?')) {
        apiClient.delete('api/security-companies/' + Id).then(response => {
          if (!response.error) {
            if (response.data.error === true) {
              this.$notification.error({
                message: response.data.message,
              })
            } else {
              this.$notification.success({
                message: response.data.message,
              })
              $('#security_companies_list').DataTable().destroy()
              this.getSecurityCompanies()
            }
          } else {
            this.$notification.error({
              message: 'Deleted Failed',
            })
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },
    resetForm() {
      this.btnEdit = false
      this.security_company = {}
    },
  },
}
</script>

<style scoped>

</style>
